import React from 'react'

import Layout from '../layouts'
import Grid from '../components/elements/grid'
import { Body, SizedText } from '../components/elements/typography'
import Icon from '../components/elements/icon'
import { Link } from 'gatsby'

import checkIcon from '../theme/icons/green-check.svg'

const OrderSuccessPage = () => {

  return (
    <Layout isSubMenuOpen={false} forceRootMenu="/">
      <Grid.FullWidthSection css={{padding: `6rem 0`, alignItems: `center`}}>
        <Grid.Block start={[2,2,2,2,3,4]} span={[12,12,12,12,10,8]} css={{backgroundColor: `#fff`, overflow: `hidden`}}>
          <div css={{display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 1fr`, 'div': {height: `0.702rem`},}}>
            <div css={{backgroundColor: `#00b4e5`}}></div><div css={{backgroundColor: `#0376bd`}}></div><div css={{backgroundColor: `#005ea8`}}></div><div css={{backgroundColor: `#192851`}}></div>
          </div>


          <div css={{ 
            padding: `4rem 2rem`,
            '@media (min-width: 1024px)': {
              padding: `4rem`
            }
          }}>
            <SizedText as="h1" smallSize="5.824" largeSize="9.373" css={{textAlign: `center`, marginBottom: `3rem`}}>
              <Icon icon={checkIcon} overrides={{verticalAlign: `-0.65rem !important`}}/>
            </SizedText>
            <SizedText as="h1" smallSize="1.802" largeSize="3.653" css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, marginBottom: `2rem`}}>
              We got it! Your Card Is On The Way! 
            </SizedText>
            <Body>
              Your order has been submitted. Please give us a few minutes to process the request.  A TCB customer service representative will reach out to you by phone to verify the request prior to processing the order. <br /><br />
            </Body>

            <Link to="/">Go Back Home</Link>
          </div>

          <div css={{display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 1fr`, 'div': {height: `0.702rem`},}}>
            <div css={{backgroundColor: `#00b4e5`}}></div><div css={{backgroundColor: `#0376bd`}}></div><div css={{backgroundColor: `#005ea8`}}></div><div css={{backgroundColor: `#192851`}}></div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}

export default OrderSuccessPage

